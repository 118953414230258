import React from 'react';
import styled from 'styled-components';
import Navbar from './Navbar';

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Main = styled.main`
  flex: 1;
`;

const Footer = styled.footer`
  padding: 2rem 5%;
  background-color: #f5f5f5;
  text-align: center;
  margin-top: 3rem;
  
  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const FooterContent = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FooterText = styled.p`
  color: var(--secondary-color);
  font-size: 0.9rem;
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 0rem;
`;

const SocialLink = styled.a`
  color: var(--primary-color);
  font-size: 1.5rem;
  transition: color 0.3s ease;
  
  &:hover {
    color: var(--accent-color);
  }
`;

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <LayoutContainer>
      <Navbar />
      <Main>{children}</Main>
      <Footer>
        <FooterContent>
          <SocialLinks>
            <SocialLink href="https://www.linkedin.com/in/apnunez/" target="_blank" aria-label="LinkedIn">
              <i className="fab fa-linkedin"></i>
            </SocialLink>
            <SocialLink href="https://github.com/nunezal" target="_blank" aria-label="GitHub">
              <i className="fab fa-github"></i>
            </SocialLink>
          </SocialLinks>
          <FooterText>
            Made by Alan Nunez
          </FooterText>
        </FooterContent>
      </Footer>
    </LayoutContainer>
  );
};

export default Layout; 