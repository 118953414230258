import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

interface ImageGalleryProps {
  images: {
    src: string;
    alt: string;
  }[];
  projectId?: string;
}

const GalleryContainer = styled.div`
  margin-top: 2rem;
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

interface GalleryItemProps {
  isSquare?: boolean;
}

const GalleryItem = styled(motion.div)<GalleryItemProps>`
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  height: 0;
  padding-bottom: ${props => props.isSquare ? '100%' : '75%'}; /* 100% for square, 75% for 4:3 landscape */
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  &:hover img {
    transform: scale(1.05);
  }
`;

const LightboxOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const LightboxImage = styled(motion.img)`
  max-width: 90%;
  max-height: 90vh;
  object-fit: contain;
  border-radius: 4px;
`;

const CloseButton = styled.button`
  position: fixed;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1001;
`;

const NavigationButton = styled.button`
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1001;
  padding: 1rem;
  
  &.prev {
    left: 20px;
  }
  
  &.next {
    right: 20px;
  }
`;

const ImageGallery: React.FC<ImageGalleryProps> = ({ images, projectId }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
  
  // Chess project (ID 3) should use square aspect ratio
  const isSquareAspectRatio = projectId === "3";
  
  const openLightbox = (index: number) => {
    setSelectedImageIndex(index);
  };
  
  const closeLightbox = () => {
    setSelectedImageIndex(null);
  };
  
  const goToPrevious = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (selectedImageIndex !== null) {
      setSelectedImageIndex((selectedImageIndex - 1 + images.length) % images.length);
    }
  };
  
  const goToNext = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (selectedImageIndex !== null) {
      setSelectedImageIndex((selectedImageIndex + 1) % images.length);
    }
  };

  return (
    <GalleryContainer>
      <GalleryGrid>
        {images.map((image, index) => (
          <GalleryItem 
            key={index}
            onClick={() => openLightbox(index)}
            whileHover={{ y: -5 }}
            transition={{ duration: 0.3 }}
            isSquare={isSquareAspectRatio}
          >
            <img src={image.src} alt={image.alt} />
          </GalleryItem>
        ))}
      </GalleryGrid>
      
      {/* Lightbox */}
      <AnimatePresence>
        {selectedImageIndex !== null && (
          <LightboxOverlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeLightbox}
          >
            <CloseButton onClick={closeLightbox}>×</CloseButton>
            <NavigationButton className="prev" onClick={goToPrevious}>
              ‹
            </NavigationButton>
            <LightboxImage 
              src={images[selectedImageIndex].src} 
              alt={images[selectedImageIndex].alt}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
            />
            <NavigationButton className="next" onClick={goToNext}>
              ›
            </NavigationButton>
          </LightboxOverlay>
        )}
      </AnimatePresence>
    </GalleryContainer>
  );
};

export default ImageGallery; 