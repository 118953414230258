import React from 'react';
import styled from 'styled-components';
import ProjectCard from './ProjectCard';
import { motion } from 'framer-motion';

interface Project {
  id: number;
  title: string;
  imageUrl: string;
  description: string;
  fullDescription?: string;
  link?: string;
  startTime?: number;
  endTime?: number;
}

interface ProjectGridProps {
  projects: Project[];
}

const GridContainer = styled.div`
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 2rem 5%;
  
  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--grid-gap);
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const ProjectGrid: React.FC<ProjectGridProps> = ({ projects }) => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  // Function to check if a project uses a video
  const isVideoProject = (imageUrl: string): boolean => {
    return typeof imageUrl === 'string' && 
      (imageUrl.endsWith('.mp4') || imageUrl.endsWith('.webm') || imageUrl.endsWith('.ogg'));
  };

  return (
    <GridContainer>
      <motion.div
        variants={container}
        initial="hidden"
        animate="show"
      >
        <Grid>
          {projects.map((project) => (
            <motion.div key={project.id} variants={item}>
              <ProjectCard 
                id={project.id}
                title={project.title}
                imageUrl={project.imageUrl}
                description={project.description}
                link={project.link}
                startTime={project.startTime}
                endTime={project.endTime}
                showHoverIndicator={isVideoProject(project.imageUrl)}
              />
            </motion.div>
          ))}
        </Grid>
      </motion.div>
    </GridContainer>
  );
};

export default ProjectGrid; 