import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import ImageGallery from '../components/ImageGallery';
import ProjectArticle from '../components/ProjectArticle';

// Import chess images
import chessImage from '../assets/images/Chess/chess.jpg';
import chessBoardImage from '../assets/images/Chess/chessBoard.jpg';
import chessBoardInsideImage from '../assets/images/Chess/chessBoardInside.jpg';
import kingImage from '../assets/images/Chess/king.jpg';
import knightImage from '../assets/images/Chess/knight.jpg';
import pawnMillImage from '../assets/images/Chess/pawnMill.jpg';
import silverChessImage from '../assets/images/Chess/silverChess.jpg';

// Import CNCSnake images
import snakeImage from '../assets/images/CNCSnake/snakeSquare.png';
import snakeWix2Image from '../assets/images/CNCSnake/snakewix2.jpg';
import snakeWix3Image from '../assets/images/CNCSnake/snakewix3.jpg';
import snakeWix4Image from '../assets/images/CNCSnake/snakewix4.jpg';
import snakeWix5Image from '../assets/images/CNCSnake/snakewix5.jpg';
import snakeWix6Image from '../assets/images/CNCSnake/snakewix6.jpg';
import snakeWix7Image from '../assets/images/CNCSnake/snakewix7.jpg';

// Import Injection Molding images
import injectionMoldingImage from '../assets/images/InjectionMolding/injectionMolding.jpg';
import mold1Image from '../assets/images/InjectionMolding/mold1.jpg';
import mold2Image from '../assets/images/InjectionMolding/mold2.jpg';
import mold4Image from '../assets/images/InjectionMolding/mold4.jpg';
import mold5Image from '../assets/images/InjectionMolding/mold5.jpg';
import mold6Image from '../assets/images/InjectionMolding/mold6.jpg';
import mold7Image from '../assets/images/InjectionMolding/mold7.jpg';
import mold8Image from '../assets/images/InjectionMolding/mold8.png';

// Import Aerial Robotics images
import aerialRoboticsImage from '../assets/images/AerialRobotics/AerialRobotics.png';
import pennAir1Image from '../assets/images/AerialRobotics/PennAir1.jpg';
import pennAir2Image from '../assets/images/AerialRobotics/PennAir2.png';
import pennAir3Image from '../assets/images/AerialRobotics/PennAir3.png';

// Import Wolf images
import wolf7Image from '../assets/images/Wolf/wolf7.jpg';
import wolf11Image from '../assets/images/Wolf/wolf11.jpg';
import wolf13Image from '../assets/images/Wolf/wolf13.jpg';
import wolf15Image from '../assets/images/Wolf/wolf15.jpg';
import wolf16Image from '../assets/images/Wolf/wolf16.jpg';
import wolf19Image from '../assets/images/Wolf/wolf19.jpg';
import wolf21Image from '../assets/images/Wolf/wolf21.jpg';
import wolf9EditedImage from '../assets/images/Wolf/wolf9_edited_edited.jpg';
import wolfTopangleImage from '../assets/images/Wolf/WolfTopangle.png';
import wolf3Image from '../assets/images/Wolf/Wolf3.jpg';

// Import Sudoku images
import contours1Image from '../assets/images/Sudoku/contours1.png';
import contours2Image from '../assets/images/Sudoku/countours2.png';
import gridInspectionImage from '../assets/images/Sudoku/gridInspection.png';
import filledSol2Image from '../assets/images/Sudoku/FilledSol2.png';
import displayingSolution1Image from '../assets/images/Sudoku/displayingSolution1.png';

// Import Stirling Engine images
import engine1Image from '../assets/images/StirlingEngine/Engine1.jpg';
import engine3Image from '../assets/images/StirlingEngine/Engine3.jpg';
import engine4Image from '../assets/images/StirlingEngine/Engine4.jpg';

// Import Path Planning gif
import pathPlanningGif from '../assets/gifs/pathPlanning.gif';
import wolfRunningGif from '../assets/gifs/Wolf2.gif';

// Import videos
import pendulumVideo from '../assets/images/PendulumSwingUp/SwingUp.mp4';
import robotTournamentVideo from '../assets/images/RobotManipulator/tournament.mp4';
import realStackingVideo from '../assets/images/RealRobotArm/realStacking.mp4';

// Import placeholder image (will be replaced with actual project images later)
import placeholderImage from '../assets/images/placeholder.jpg';

// Import poster images for videos
import pendulumPoster from '../assets/images/posters/PendulumSwingUp_SwingUp_poster.jpg';

const PageContainer = styled.div`
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 2rem 5%;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const ProjectContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin-top: 2rem;
  align-items: start;
  
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  &.with-video {
    grid-template-rows: auto 1fr;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  border: 1px solid #f0f0f0;
  padding: 1rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  
  img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  video {
    width: 100%;
    height: auto;
    display: block;
  }
  
  &.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 600px;
    padding: 0.5rem;
    
    video {
      max-height: 100%;
      object-fit: contain;
      aspect-ratio: 16/9;
      background-color: #000;
    }
    
    @media (max-width: 768px) {
      max-height: 400px;
    }
  }
`;

const ProjectInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProjectTitle = styled(motion.h1)`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  
  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
`;

const ProjectDescription = styled(motion.p)`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 2rem;
  
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const BackButton = styled(Link)`
  display: inline-block;
  margin-top: 3rem;
  font-size: 1.1rem;
  color: var(--accent-color);
  transition: color 0.3s ease;
  
  &:hover {
    color: var(--primary-color);
  }
`;

const GallerySection = styled.div`
  grid-column: 1 / -1;
  margin-top: 3rem;
`;

const GalleryTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  color: #333;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 60px;
    height: 3px;
    background-color: var(--accent-color, #0066cc);
  }
`;

// Gallery images for each project
// The chess gallery images
const chessGalleryImages = [
  { src: chessBoardImage, alt: "Chess Board" },
  { src: chessBoardInsideImage, alt: "Chess Board Inside" },
  { src: kingImage, alt: "Chess King Piece" },
  { src: knightImage, alt: "Chess Knight Piece" },
  { src: pawnMillImage, alt: "Chess Pawn Mill Process" },
  { src: silverChessImage, alt: "Silver Chess Set" }
];

// CNC Snake gallery images
const cncSnakeGalleryImages = [
  { src: snakeWix2Image, alt: "CNC Snake Detail" },
  { src: snakeWix3Image, alt: "CNC Snake Machining" },
  { src: snakeWix4Image, alt: "CNC Snake Assembly" },
  { src: snakeWix5Image, alt: "CNC Snake Close-up" },
  { src: snakeWix6Image, alt: "CNC Snake Design" },
  { src: snakeWix7Image, alt: "CNC Snake Final" }
];

// Injection Molding gallery images
const injectionMoldingGalleryImages = [
  { src: mold2Image, alt: "Mold Assembly" },
  { src: mold4Image, alt: "Injection Molding Process" },
  { src: mold5Image, alt: "Final Molded Parts" },
  { src: mold6Image, alt: "Injection Mold Design" },
  { src: mold7Image, alt: "Mold Cavity" },
  { src: mold8Image, alt: "Molding Process Simulation" }
];

// Aerial Robotics gallery images
const aerialRoboticsGalleryImages = [
  { src: pennAir1Image, alt: "Aerial Robotics Drone" },
  { src: pennAir2Image, alt: "Drone Flight Test" },
  { src: pennAir3Image, alt: "Drone Control System" }
];

// Wolf gallery images
const wolfGalleryImages = [
  { src: wolfTopangleImage, alt: "Wolf Sculpture Top View" },
  { src: wolf3Image, alt: "Wolf assembly view" },
  // { src: wolf7Image, alt: "Close up CAD assembly of Wolf" },
  // { src: wolf11Image, alt: "Wax cast" },
  // { src: wolf13Image, alt: "Wolf head layup" },
  { src: wolf15Image, alt: "Machining of base braket" },
  { src: wolf16Image, alt: "Machining on mill" },
  { src: wolf19Image, alt: "Assembly organized on table" },
  { src: wolf21Image, alt: "Group assembly of Wolf" }
];

// Sudoku gallery images
const sudokuGalleryImages = [
  { src: contours1Image, alt: "Binary image with Sudoku grid" },
  { src: contours2Image, alt: "Detected corners of Sudoku grid" },
  { src: gridInspectionImage, alt: "Grid inspection process" },
  { src: filledSol2Image, alt: "Neural network code" },
  { src: displayingSolution1Image, alt: "Solved Sudoku grid" }
];

// Stirling Engine gallery images
const stirlingEngineGalleryImages = [
  { src: engine1Image, alt: "Stirling Engine" },
  { src: engine3Image, alt: "Stirling Engine Components" },
  { src: engine4Image, alt: "Stirling Engine Assembly" }
];

// Path Planning gallery images
const pathPlanningGalleryImages = [
  { src: pathPlanningGif, alt: "Path Planning Algorithm Visualization" }
];

// Create empty gallery arrays for other projects (to be filled later)
const robotManipulatorGalleryImages: { src: string; alt: string }[] = [];
const pendulumSwingUpGalleryImages: { src: string; alt: string }[] = [];
const realRobotArmGalleryImages: { src: string; alt: string }[] = [];

// Map to store the relationship between project IDs and their gallery images
const projectGalleries: Record<string, { src: string; alt: string }[]> = {
  "1": robotManipulatorGalleryImages,
  "2": wolfGalleryImages,
  "3": chessGalleryImages,
  "4": cncSnakeGalleryImages,
  "5": pendulumSwingUpGalleryImages,
  "6": pathPlanningGalleryImages,
  "7": stirlingEngineGalleryImages, 
  "8": injectionMoldingGalleryImages,
  "9": sudokuGalleryImages,
  "10": aerialRoboticsGalleryImages,
  "11": realRobotArmGalleryImages
};

// This will be replaced with actual data fetching from your projects database or file
const getProjectById = (id: string) => {
  const allProjects = [
    {
      id: "11",
      title: "Real Robot Arm",
      imageUrl: realStackingVideo,
      fullDescription: "This real robot arm project involved building and programming a physical robotic arm that can perform precise movements and interact with objects in the real world. The system includes position sensors, servo motors, and a sophisticated control interface."
    },
    {
      id: "1",
      title: "Robot Manipulator",
      imageUrl: robotTournamentVideo,
      fullDescription: "This robotic manipulator project involved designing and implementing a control system for a 6-degree-of-freedom robot arm. The system features inverse kinematics calculations for precise positioning and real-time trajectory planning.",
    },
    {
      id: "10",
      title: "Aerial Robotics",
      imageUrl: aerialRoboticsImage,
      fullDescription: "This project was part of the 2018-2019 AIAA Design/Build/Fly (DBF) competition, where our team designed a naval bomber capable of launching from an aircraft carrier."
    },
    {
      id: "6",
      title: "Path Planning",
      imageUrl: pathPlanningGif,
      fullDescription: "This path planning system implements several algorithms including A*, RRT, and potential field methods to navigate through obstacle-filled environments. The solution optimizes for both path length and computational efficiency."
    },
    {
      id: "5",
      title: "Pendulum Swing Up",
      imageUrl: pendulumVideo,
      fullDescription: "The pendulum swing-up project demonstrates principles of dynamic control systems. The implemented algorithm successfully brings the pendulum from its natural hanging position to an inverted balanced state with minimal oscillation."
    },
    {
      id: "9",
      title: "Sudoku",
      imageUrl: displayingSolution1Image,
      fullDescription: "This Sudoku solver project implements Computer Vision and Neural Networks to efficiently solve puzzles of varying difficulty. The integrated visualization system allows users to observe the solving process step by step."
    },
    {
      id: "2",
      title: "Wolf",
      imageUrl: wolfRunningGif,
      fullDescription: "This project demonstrates techniques in machining, carbon fiber, and collaboration on large CAD assemblies to create a meticulously crafted wolf sculpture."
    },
    {
      id: "3",
      title: "Chess",
      imageUrl: chessImage,
      fullDescription: "In a team of four, we designed, machined, and hand polished a complete chess set. The parts were 3D printed to determine correct sizing. A CNC lathe was used to cut the profiles of all the parts. Manual and CNC milling was used afterwards to add character to each piece."
    },
    {
      id: "4",
      title: "CNC Snake",
      imageUrl: snakeImage,
      fullDescription: "A CNC-milled snake desk sculpture showcasing advanced machining techniques."
    },
    {
      id: "7",
      title: "Stirling Engine",
      imageUrl: engine1Image,
      fullDescription: "A fully functional Stirling heat engine built from scratch. This semester long project involved precision machining all the critical components and careful assembling them to create an efficient heat engine."
    },
    {
      id: "8",
      title: "Injection Molding",
      imageUrl: injectionMoldingImage,
      fullDescription: "This injection molding project covered the entire process from mold design to final production. Special attention was given to material selection, gate placement, and cooling channel design to ensure high-quality parts with minimal defects."
    }
  ];
  
  return allProjects.find(project => project.id === id);
};

const ProjectDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const project = getProjectById(id || "");
  
  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  if (!project) {
    return <PageContainer>Project not found</PageContainer>;
  }
  
  // Check if project has gallery images
  const hasGallery = id && projectGalleries[id] && projectGalleries[id].length > 0;
  
  // Hide gallery for Path Planning project
  const shouldDisplayGallery = id !== "6" && hasGallery;
  
  // Check if the project media is a video (for pendulum, robot manipulator, or real robot arm project)
  const isVideoProject = id === "5" || id === "1" || id === "11";
  // Check specifically for Robot Manipulator project
  const isRobotManipulator = id === "1";
  
  return (
    <PageContainer>
      <ProjectContent className={isRobotManipulator ? 'with-video' : ''}>
        <ImageContainer className={isRobotManipulator ? 'video-container' : ''}>
          {isVideoProject ? (
            <video width="100%" controls loop muted poster={
              id === "5" ? pendulumPoster : 
              undefined
            }>
              <source src={
                id === "5" ? pendulumVideo : 
                id === "1" ? robotTournamentVideo : 
                realStackingVideo
              } type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={project.imageUrl} alt={project.title} />
          )}
        </ImageContainer>
        
        <ProjectInfo>
          <ProjectTitle
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {project.title}
          </ProjectTitle>
          
          <ProjectDescription
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            {project.fullDescription}
          </ProjectDescription>
          
          <BackButton to="/">BACK</BackButton>
        </ProjectInfo>
        
        {shouldDisplayGallery && (
          <GallerySection>
            <GalleryTitle>Project Gallery</GalleryTitle>
            <ImageGallery images={projectGalleries[id]} projectId={id} />
          </GallerySection>
        )}

        {/* Project Article - will only display if content exists for this project */}
        <ProjectArticle projectId={id || ""} />
      </ProjectContent>
    </PageContainer>
  );
};

export default ProjectDetail; 