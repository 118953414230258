import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const PageContainer = styled.div`
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 2rem 0;
  position: relative;
  
  @media print {
    padding: 0;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

const ResumeContent = styled.div`
  padding: 0 5%;
  margin-top: 3rem;
  
  @media (max-width: 768px) {
    padding: 0 1rem;
  }
  
  @media print {
    margin-top: 0;
    padding: 0.5in 0.5in;
    font-size: 10pt;
    max-height: 11in;
    overflow: hidden;
  }
`;

const ResumeHeader = styled.div`
  display: none;
  
  @media print {
    display: block;
    text-align: center;
    margin-bottom: 1rem;
  }
`;

const PersonName = styled.h1`
  font-size: 24pt;
  font-weight: bold;
  margin-bottom: 0.25rem;
`;

const ContactInfo = styled.div`
  font-size: 10pt;
  color: #555;
`;

const Section = styled(motion.section)`
  margin-bottom: 3rem;
  
  @media print {
    margin-bottom: 1.25rem;
    page-break-inside: avoid;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid var(--accent-color);
  
  @media print {
    font-size: 14pt;
    margin-bottom: 0.4rem;
    padding-bottom: 0.2rem;
  }
`;

const ExperienceItem = styled.div`
  margin-bottom: 2rem;
  
  @media print {
    margin-bottom: 0.8rem;
    page-break-inside: avoid;
  }
`;

const ExperienceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  
  @media print {
    margin-bottom: 0.1rem;
  }
`;

const JobInfo = styled.div``;

const JobTitle = styled.h3`
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  
  @media print {
    font-size: 11pt;
    margin-bottom: 0.15rem;
  }
`;

const Company = styled.h4`
  font-size: 1.2rem;
  color: var(--secondary-color);
  margin-bottom: 0.5rem;
  
  @media print {
    font-size: 10pt;
    margin-bottom: 0.15rem;
  }
`;

const Period = styled.p`
  font-size: 1rem;
  font-style: italic;
  text-align: right;
  
  @media print {
    font-size: 10pt;
  }
`;

const Description = styled.div`
  font-size: 1rem;
  line-height: 1.6;

  ul {
    margin-top: 0.5rem;
    padding-left: 1.5rem;
  }

  li {
    margin-bottom: 0.5rem;
  }
  
  @media print {
    font-size: 9pt;
    line-height: 1.3;
    
    ul {
      margin-top: 0.1rem;
      padding-left: 1.2rem;
    }
    
    li {
      margin-bottom: 0.15rem;
    }
  }
`;

const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  
  @media print {
    gap: 0.3rem;
  }
`;

const Skill = styled.div`
  background-color: #f5f5f5;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 1rem;
  
  @media print {
    font-size: 9pt;
    padding: 0.2rem 0.5rem;
    background-color: #f5f5f5 !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
`;

const Education = styled.div`
  margin-bottom: 1.5rem;
  
  @media print {
    margin-bottom: 0.8rem;
    page-break-inside: avoid;
  }
`;

const EducationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const EducationInfo = styled.div`
  max-width: 80%;
`;

const Degree = styled.h3`
  font-size: 1.3rem;
  margin-bottom: 0.3rem;
  
  @media print {
    font-size: 11pt;
    margin-bottom: 0.1rem;
  }
`;

const School = styled.h4`
  font-size: 1.1rem;
  color: var(--secondary-color);
  font-weight: normal;
  
  @media print {
    font-size: 9pt;
  }
`;

const PrintButtons = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  gap: 1rem;
  z-index: 100;
  
  @media print {
    display: none;
  }
`;

const PrintButton = styled.button`
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
  &:hover {
    background-color: var(--accent-hover-color, #0056b3);
  }
`;

// Add a specialized component for print compression
const CompressedList = styled.ul`
  @media print {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
`;

const Resume: React.FC = () => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };
  
  const handlePrint = () => {
    window.print();
  };
  
  const handleDownloadPDF = () => {
    // Set print media to simulate print
    const style = document.createElement('style');
    style.textContent = `
      @media screen {
        body { visibility: hidden; }
        .resume-content-wrapper { visibility: visible; position: absolute; left: 0; top: 0; }
        nav, footer, button, .header, .footer { display: none !important; }
      }
    `;
    document.head.appendChild(style);
    
    // Prepare for print
    const originalTitle = document.title;
    document.title = "Resume.pdf";
    
    // Print as PDF
    window.print();
    
    // Clean up
    document.title = originalTitle;
    document.head.removeChild(style);
  };

  return (
    <>
      <style>
        {`
          @media print {
            body, html {
              margin: 0;
              padding: 0;
              width: 100%;
              height: 100%;
              color: black;
              background-color: white;
              font-size: 11pt;
              line-height: 1.2;
              -webkit-print-color-adjust: exact !important;
              print-color-adjust: exact !important;
            }
            @page {
              size: letter portrait;
              margin: 0;
              scale: 95%;
            }
            nav, footer, button, .header, .footer {
              display: none !important;
            }
            * {
              box-shadow: none !important;
              text-shadow: none !important;
            }
            strong {
              font-weight: bold !important;
            }
            .motion-div > section:last-child {
              margin-bottom: 0 !important;
            }
            .resume-item {
              break-inside: avoid;
            }
            p, h1, h2, h3, h4, h5, h6 {
              margin-top: 0 !important;
              margin-bottom: 0.2rem !important;
            }
            ul, ol {
              margin: 0.2rem 0 !important;
              padding-left: 1rem !important;
            }
            li {
              line-height: 1.3 !important;
              margin-bottom: 0.2rem !important;
            }
            .resume-content-wrapper {
              padding-top: 0.25in !important;
            }
          }
        `}
      </style>
      <PageContainer>
        <ResumeContent className="resume-content-wrapper">
          <ResumeHeader>
            <PersonName>Alan Nuñez</PersonName>
            <ContactInfo>
              alannunez1679@gmail.com | (914) 346-7640
            </ContactInfo>
          </ResumeHeader>
          
          <motion.div
            className="motion-div"
            variants={container}
            initial="hidden"
            animate="show"
          >
            <Section variants={item}>
              <SectionTitle>Experience</SectionTitle>
              <ExperienceItem className="resume-item">
                <ExperienceHeader>
                  <JobInfo>
                    <JobTitle>Founder & Developer</JobTitle>
                    <Company>Chore Focus</Company>
                  </JobInfo>
                  <Period>August 2023 - Present</Period>
                </ExperienceHeader>
                <Description>
                  <CompressedList>
                    <li>Designed, developed, and launched an chore tracking app, growing it to <strong>1,700+ monthly active users</strong>.</li>
                    <li>Managed all aspects of the product lifecycle, from UI/UX design to backend development and deployment.</li>
                    <li>Implemented user feedback-driven improvements, enhancing retention and engagement.</li>
                  </CompressedList>
                </Description>
              </ExperienceItem>
              
              <ExperienceItem className="resume-item">
                <ExperienceHeader>
                  <JobInfo>
                    <JobTitle>Mechanical Engineer</JobTitle>
                    <Company>Nuro</Company>
                  </JobInfo>
                  <Period>February 2022 - May 2023</Period>
                </ExperienceHeader>
                <Description>
                  <CompressedList>
                    <li>Owned remote control mechanical design focusing on improving ergonomics for a new control application.</li>
                    <li>Iterated design of ergonomic plastic part with complex curvature, creating over 10 3D prints and 5 injection-molded prototypes while interfacing with overseas vendors for DFM review and production kickoff.</li>
                    <li>Created and conducted user study with 15 depot users, qualifying user-centered design and resulting in a <strong>14% increase in average user satisfaction</strong>.</li>
                  </CompressedList>
                </Description>
              </ExperienceItem>
            </Section>
            
            <Section variants={item}>
              <SectionTitle>Education</SectionTitle>
              <Education>
                <EducationHeader>
                  <EducationInfo>
                    <Degree>Master of Science in Robotics</Degree>
                    <School>University of Pennsylvania, School of Engineering and Applied Science</School>
                  </EducationInfo>
                  <Period>12/2021</Period>
                </EducationHeader>
              </Education>
              
              <Education>
                <EducationHeader>
                  <EducationInfo>
                    <Degree>Bachelor of Science in Mechanical Engineering & Applied Mechanics</Degree>
                    <School>University of Pennsylvania, School of Engineering and Applied Science</School>
                  </EducationInfo>
                  <Period>05/2021</Period>
                </EducationHeader>
              </Education>
            </Section>
            
            <Section variants={item}>
              <SectionTitle>Projects</SectionTitle>
              
              <ExperienceItem className="resume-item">
                <ExperienceHeader>
                  <JobInfo>
                    <JobTitle>Robot Manipulator</JobTitle>
                    <Company>Personal Project</Company>
                  </JobInfo>
                  <Period>2024</Period>
                </ExperienceHeader>
                <Description>
                  <CompressedList>
                    <li>Developed and implemented a vision-based control system for a 6-DOF robotic arm to autonomously pick and stack blocks.</li>
                    <li>Integrated computer vision for object detection, localization, and pose estimation.</li>
                    <li>Refined inverse kinematics and trajectory planning algorithms for precise manipulation and stacking tasks.</li>
                  </CompressedList>
                </Description>
              </ExperienceItem>
              
              <ExperienceItem className="resume-item">
                <ExperienceHeader>
                  <JobInfo>
                    <JobTitle>Path Planning Algorithm</JobTitle>
                    <Company>Autonomous Systems Project</Company>
                  </JobInfo>
                  <Period>2020</Period>
                </ExperienceHeader>
                <Description>
                  <CompressedList>
                    <li>Implemented and evaluated A*, RRT, and potential field path planning algorithms for robot navigation.</li>
                    <li>Optimized algorithms for path length and computational efficiency in obstacle-filled environments.</li>
                    <li>Developed visualization tools to analyze and demonstrate algorithm performance.</li>
                  </CompressedList>
                </Description>
              </ExperienceItem>

              <ExperienceItem className="resume-item">
                <ExperienceHeader>
                  <JobInfo>
                    <JobTitle>Aerial Robotics</JobTitle>
                    <Company>University of Pennsylvania</Company>
                  </JobInfo>
                  <Period>2018 - 2019</Period>
                </ExperienceHeader>
                <Description>
                  <CompressedList>
                    <li>Competed in the 2018-2019 AIAA Design/Build/Fly competition, contributing to the design of a naval bomber drone.</li>
                    <li>Focused on airframe design, systems integration, and flight testing procedures for competition requirements.</li>
                  </CompressedList>
                </Description>
              </ExperienceItem>
            </Section>
            
            <Section variants={item}>
              <SectionTitle>Skills</SectionTitle>
              <SkillsContainer>
                <Skill>Python</Skill>
                <Skill>C++</Skill>
                <Skill>Arduino</Skill>
                <Skill>SolidWorks</Skill>
                <Skill>NX</Skill>
                <Skill>Isaac Sim</Skill>
                <Skill>Gazebo</Skill>
                <Skill>OpenCV</Skill>
                <Skill>TensorFlow</Skill>
                <Skill>PyTorch</Skill>
                <Skill>Keras</Skill>
              </SkillsContainer>
            </Section>
          </motion.div>
        </ResumeContent>
      </PageContainer>
    </>
  );
};

export default Resume; 