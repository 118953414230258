import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import ProjectGrid from '../components/ProjectGrid';
// Import image and gif assets
import chessImage from '../assets/images/Chess/chess.jpg';
import robotManipulatorVideo from '../assets/images/RobotManipulator/RobotManipulator.mp4';
import pathPlanningVideo from '../assets/images/PathPlanning/pathPlanning.mp4';
import sudokuVideo from '../assets/images/Sudoku/soduko.mp4';
import wolfTopangleImage from '../assets/images/Wolf/WolfTopangle.png';
import stateSpaceVideo from '../assets/images/PendulumSwingUp/stateSpace.mp4';
import engineImage from '../assets/images/StirlingEngine/Engine1.jpg';
import injectionMoldingImage from '../assets/images/InjectionMolding/injectionMolding.jpg';
import cncSnakeImage from '../assets/images/CNCSnake/snake.png';
import aerialRoboticsImage from '../assets/images/AerialRobotics/AerialRobotics.png';
import placeholderImage from '../assets/images/placeholder.jpg';
import realStackingVideo from '../assets/images/RealRobotArm/stackingShort.mp4';

const PageContainer = styled.div`
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 2rem 0;
`;

const Header = styled.header`
  text-align: center;
  padding: 3rem 5%;
  
  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const Description = styled(motion.p)`
  font-size: 1.2rem;
  color: var(--secondary-color);
  max-width: 700px;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

// Sample project data
const allProjects = [
  {
    id: 11,
    title: "Real Robot Arm",
    imageUrl: realStackingVideo,
    description: "A physical robotic arm project with real-world applications.",
    fullDescription: "This real robot arm project involved building and programming a physical robotic arm that can perform precise movements and interact with objects in the real world. The system includes position sensors, servo motors, and a sophisticated control interface.",
    startTime: 28,
    endTime: 36,
  },
  {
    id: 1,
    title: "Robot Manipulator",
    imageUrl: robotManipulatorVideo,
    description: "A robotic arm control system with precise movement capabilities.",
    fullDescription: "This robotic manipulator project involved designing and implementing a control system for a 6-degree-of-freedom robot arm. The system features inverse kinematics calculations for precise positioning and real-time trajectory planning.",
    startTime: 0,
    endTime: 5, 
  },
  {
    id: 10,
    title: "Aerial Robotics",
    imageUrl: aerialRoboticsImage,
    description: "Development of autonomous control systems for aerial vehicles.",
    fullDescription: "This aerial robotics project focused on creating robust control systems for quadcopters. Features include autonomous navigation, obstacle avoidance, and precise positioning using a combination of sensor fusion and computer vision techniques."
  },
  {
    id: 6,
    title: "Path Planning",
    imageUrl: pathPlanningVideo,
    description: "Algorithmic solution for efficient navigation through complex environments.",
    fullDescription: "This path planning system implements several algorithms including A*, RRT, and potential field methods to navigate through obstacle-filled environments. The solution optimizes for both path length and computational efficiency.",
    startTime: 0,
    endTime: 5,
  },
  {
    id: 5,
    title: "Pendulum Swing Up",
    imageUrl: stateSpaceVideo,
    description: "A control system for stabilizing an inverted pendulum.",
    fullDescription: "The pendulum swing-up project demonstrates principles of dynamic control systems. The implemented algorithm successfully brings the pendulum from its natural hanging position to an inverted balanced state with minimal oscillation."
  },
  {
    id: 9,
    title: "Sudoku",
    imageUrl: sudokuVideo,
    description: "A sophisticated Sudoku solver with visualization capabilities.",
    fullDescription: "This Sudoku solver project implements advanced constraint satisfaction algorithms to efficiently solve puzzles of varying difficulty. The integrated visualization system allows users to observe the solving process step by step.",
    startTime: 0,
    endTime: 5
  },
  {
    id: 2,
    title: "Wolf",
    imageUrl: wolfTopangleImage,
    description: "A detailed wolf sculpture and 3D modeling project.",
    fullDescription: "A meticulously crafted wolf sculpture with anatomically accurate details. This project demonstrates techniques in digital sculpting, 3D printing, and manual finishing to create a lifelike representation."
  },
  {
    id: 3,
    title: "Chess",
    imageUrl: chessImage,
    description: "A custom-designed metal chess set with unique pieces.",
    fullDescription: "In a team of four, we designed, machined, and hand polished a complete chess set. The parts were 3D printed to determine correct sizing. A CNC lathe was used to cut the profiles of all the parts. Manual and CNC milling was used afterwards to add character to each piece."
  },
  {
    id: 4,
    title: "CNC Snake",
    imageUrl: cncSnakeImage,
    description: "A snake-inspired design manufactured using CNC technology.",
    fullDescription: "This CNC-manufactured snake design showcases advanced machining techniques. The segmented body allows for realistic movement while maintaining structural integrity throughout the piece."
  },
  {
    id: 7,
    title: "Stirling Engine",
    imageUrl: engineImage,
    description: "A functional Stirling engine with custom-designed components.",
    fullDescription: "A fully functional Stirling heat engine designed and built from scratch. This project involved thermodynamic calculations, precision machining of critical components, and careful assembly to create an efficient external combustion engine."
  },
  {
    id: 8,
    title: "Injection Molding",
    imageUrl: injectionMoldingImage,
    description: "Design and production of parts using injection molding techniques.",
    fullDescription: "This injection molding project covered the entire process from mold design to final production. Special attention was given to material selection, gate placement, and cooling channel design to ensure high-quality parts with minimal defects."
  }
];

const Portfolio: React.FC = () => {
  return (
    <PageContainer>
      <ProjectGrid projects={allProjects} />
    </PageContainer>
  );
};

export default Portfolio; 