import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './styles/GlobalStyles';
import Layout from './components/Layout';
import Portfolio from './pages/Portfolio';
import Resume from './pages/Resume';
import About from './pages/About';
import ProjectDetail from './pages/ProjectDetail';

// Theme object for styled-components
const theme = {
  colors: {
    primary: '#333',
    secondary: '#666',
    background: '#fafafa',
    text: '#333',
    accent: '#0066cc',
  },
  fonts: {
    main: "'Helvetica Neue', Arial, sans-serif",
  },
  breakpoints: {
    mobile: '768px',
    tablet: '1024px',
  }
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Portfolio />} />
            <Route path="/projects/:id" element={<ProjectDetail />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
