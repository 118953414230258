import React from 'react';
import styled from 'styled-components';

// Types for the article content
export interface ArticleSection {
  title: string;
  content: string;
  images?: {
    src: string;
    alt: string;
    size?: 'small' | 'medium' | 'large' | 'full'; // Optional size parameter
    aspectRatio?: string; // New property for custom aspect ratio
  }[];
  video?: {
    src: string;
    poster?: string;
  };
  imageLayout?: 'grid' | 'row' | 'twoColumn' | 'threeColumn' | 'singleColumnFixed'; // Optional layout parameter
}

interface ProjectArticleProps {
  projectId: string;
}

// Styled components for the article
const ArticleContainer = styled.div`
  grid-column: 1 / -1;
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

const SectionContainer = styled.section`
  margin-bottom: 6rem;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  color: #333;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 60px;
    height: 3px;
    background-color: var(--accent-color, #0066cc);
  }
`;

const SectionContent = styled.div`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 2.5rem;
  color: #444;
  max-width: 900px;
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2.5rem 0;
`;

const ArticleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

const ImageContainer = styled.div<{isFullWidth?: boolean, aspectRatio?: string}>`
  width: 100%;
  padding-top: ${props => {
    if (props.aspectRatio) return props.aspectRatio;
    return props.isFullWidth ? '56.25%' : '66.67%'; /* 16:9 for full width, 3:2 for regular */
  }};
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const StyledArticleImage = styled(ArticleImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

// Add new styled components for different layouts
const ImageRow = styled.div`
  display: flex;
  gap: 2rem;
  margin: 2.5rem 0;
  flex-wrap: wrap;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin: 2.5rem 0;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ThreeColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 2.5rem 0;
  
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const SingleColumnFixed = styled.div<{size?: string}>`
  display: grid;
  grid-template-columns: ${props => {
    switch (props.size) {
      case 'small':
        return 'minmax(auto, 400px)';
      case 'medium':
        return 'minmax(auto, 500px)';
      case 'large':
        return 'minmax(auto, 700px)';
      case 'full':
      default:
        return 'minmax(auto, 600px)';
    }
  }};
  gap: 2rem;
  margin: 2.5rem 0;
  justify-content: center;
`;

interface ImageWrapperProps {
  size?: 'small' | 'medium' | 'large' | 'full';
}

const ImageWrapper = styled.div<ImageWrapperProps>`
  ${props => {
    switch (props.size) {
      case 'small':
        return 'flex: 0 0 30%;';
      case 'medium':
        return 'flex: 0 0 45%;';
      case 'large':
        return 'flex: 0 0 65%;';
      case 'full':
        return 'flex: 0 0 100%;';
      default:
        return 'flex: 1;';
    }
  }}
  
  @media (max-width: 768px) {
    flex: 0 0 100%;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 2.5rem auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  
  video {
    width: 100%;
    display: block;
  }
`;

// Map of project content
const projectArticles: Record<string, ArticleSection[]> = {
  // Project ID 1 - Robot Manipulator
  "1": [
    {
      title: "Competition Overview",
      content: "This project was completed by a team of 4 in a graduate-level robotics course within 2 weeks. The goal was to create a planner for a robot manipulator to collect and orient blocks in head-to-head tournaments.",
      images: [
        { 
          src: require('../assets/images/RobotManipulator/robotSTack.png'), 
          alt: "Robot manipulator stacking blocks",
          size: 'small'
        }
      ],
      imageLayout: 'singleColumnFixed'
    },
    {
      title: "Implementation",
      content: "The robot (shown in red in the video) had to collect blocks onto its platform within a 1-minute time limit. Bonuses were awarded for white-side-up orientation and stacking. The planner was written in Python and simulated in the ROS-based Gazebo platform.",
      imageLayout: 'singleColumnFixed'
    }
  ],
  
  // Project ID 2 - Wolf
  "2": [
    {
      title: "Design",
      content: "Our goal was to recreate the pattern of leg and head movement that a wolf displays while sprinting. All torque is generated by the DC motor in the center of the body and transferred using a worm gear to a system of sprockets attached to drive shafts. The arrangement of links were created with trial and error in a mechanical simulation program called Linkage.",
      images: [
        { 
          src: require('../assets/gifs/WolfRuning.gif'), 
          alt: "Wolf design sketch",
          size: 'medium'
        },
        { 
          src: require('../assets/images/Wolf/wolf7.jpg'), 
          alt: "Wolf mechanical design",
          size: 'medium'
        }
      ],
      imageLayout: 'row'
    },
    {
      title: "Machining",
      content: "All of the linkages were cut from aluminium stock on ProtoTRAK CNC/manual mills. The shafts and spacers were cut to length on manual lathes.",
      images: [
        { 
          src: require('../assets/images/Wolf/wolf15.jpg'), 
          alt: "Wolf machining process" 
        },
        { 
          src: require('../assets/images/Wolf/wolf16.jpg'), 
          alt: "Wolf parts machining" 
        }
      ],
      imageLayout: 'twoColumn'
    },
    {
      title: "Aesthetic Pieces",
      content: "The head of the wolf was modeled in SolidWorks and segmented into 2D sheets using the software SlicerFusion. The 2D slices were then laser cut and glued together and covered in carbon fiber.<br><br>The carbon fiber arm covers were modeled in Solidworks and made using a negative mold created from machinable wax.",
      images: [
        { 
          src: require('../assets/images/Wolf/wolf13.jpg'), 
          alt: "Carbon fiber process"
        },
        { 
          src: require('../assets/images/Wolf/wolf11.jpg'), 
          alt: "Wolf segments"
        }
      ],
      imageLayout: 'twoColumn'
    },
    {
      title: "Assembly",
      content: "Once all of the parts were made, the final assembly was put together in a span of several hours spread out between 3 days.",
      images: [
        { 
          src: require('../assets/images/Wolf/wolf19.jpg'), 
          alt: "Parts organization"
        },
        { 
          src: require('../assets/images/Wolf/wolf21.jpg'), 
          alt: "Final assembly" 
        }
      ],
      imageLayout: 'twoColumn'
    }
  ],
  
  // Project ID 4 - CNC Snake
  "4": [
    {
      title: "Design",
      content: "The snake was modeled in SolidWorks and the tool path was generated with SolidWorks CAM.",
      images: [
        
        { 
          src: require('../assets/images/CNCSnake/snakewix2.jpg'), 
          alt: "Snake assembly" 
        }
        
      ],
      imageLayout: 'singleColumnFixed'
    },
    {
      title: "Machining",
      content: "The snakced was CNC machined out of a solid block of aluminum",
      images: [
        { 
          src: require('../assets/images/CNCSnake/snakewix3.jpg'), 
          alt: "Snake manufacturing process" 
        },
        { 
          src: require('../assets/images/CNCSnake/snakewix5.jpg'), 
          alt: "Hand polishing",
          size: 'full'
        }
      ],
      imageLayout: 'twoColumn'
    },
    {
      title: "Hand Polishing",
      content: "The snake was hand polished over the course of a few days.",
      images: [
        { 
          src: require('../assets/images/CNCSnake/snakewix6.jpg'), 
          alt: "Snake design",
          size: 'full'
        },
        { 
          src: require('../assets/images/CNCSnake/snakewix7.jpg'), 
          alt: "Hand polishing",
          size: 'full'
        }
      ],
      imageLayout: 'twoColumn'
    }
  ],
  
  // Project ID 8 - Injection Molding
  "8": [
    {
      title: "Design",
      content: "The mold was initially designed in CAD software, creating a detailed LEGO-style figure with all required features and dimensions. The process involved careful consideration of part geometry, draft angles, and parting lines to ensure proper molding.<br><br>CAM toolpaths were then generated to prepare for the CNC machining process. Each toolpath was optimized for tool life, surface finish, and cycle time efficiency.",
      images: [
        { 
          src: require('../assets/images/InjectionMolding/mold8.png'), 
          alt: "Mold design simulation",
          size: 'medium'
        },
        { 
          src: require('../assets/images/InjectionMolding/mold2.jpg'), 
          alt: "CAD design of mold",
          size: 'medium'
        }
      ],
      imageLayout: 'row'
    },
    {
      title: "CNC Milling",
      content: "A solid aluminum block was secured to the CNC machine bed and the roughing operations began to remove excess material. Multiple passes were required to establish the basic cavity shape.<br><br>As the milling operations progressed, increasingly finer tooling was employed to define the intricate details of the mold, including the figure geometry and brick patterns.<br><br>The final machining operations produced a clean and dimensionally accurate mold with the precise surface finish required for the injection molding process.",
      images: [
        { 
          src: require('../assets/images/InjectionMolding/mold4.jpg'), 
          alt: "Mold manufacturing" 
        },
        { 
          src: require('../assets/images/InjectionMolding/mold7.jpg'), 
          alt: "Mold cavities" 
        }
      ],
      imageLayout: 'twoColumn'
    },
    {
      title: "Injection Molding",
      content: "The completed mold was mounted into the injection molding press and aligned properly. Temperature and pressure parameters were set according to the specific plastic material requirements.<br><br>Molten plastic was then injected into the mold cavity under high pressure. After a calculated cooling period, the mold was opened to reveal the successfully produced LEGO-style figure with all intended details intact.",
      images: [
        { 
          src: require('../assets/images/InjectionMolding/mold5.jpg'), 
          alt: "Quality inspection" 
        }
        
      ],
      imageLayout: 'singleColumnFixed'
    }
  ],
  
  // Project ID 9 - Sudoku
  "9": [
    {
      title: "Contours",
      content: "First the webcam image is converted into a black and white binary image. The contours in the image are detected and the largest 4 sided contour above a certain area is detected. The coordinates of this quadrilateral are then used to isolate and warp the Sudoku grid into a square.",
      images: [
        { 
          src: require('../assets/images/Sudoku/contours1.png'), 
          alt: "Binary image with Sudoku grid"
        },
        { 
          src: require('../assets/images/Sudoku/countours2.png'), 
          alt: "Detected corners of Sudoku grid"
        }
      ],
      imageLayout: 'twoColumn'
    },
    {
      title: "Grid Inspection",
      content: "The 81 boxes in the grid are inspected to determine if they are empty or if there is a number in the grid. If there is a number, the contours in the grid are found. If the contour's aspect ratio is in the range that is expected for a number, it is passed to the neural network for identification.<br><br>The convolutional neural network is trained with 28 by 28 pixel images of the numbers 1-9 from the MNIST dataset of handwritten digits. The 63,097 handwritten images were mixed with 9,144 typed digit images from Chars74K dataset and trained on a Google Colab notebook. (The digit identification is the bottleneck of the program, so I kept track of the grids with valid solutions and assume the grid in question is one of the saved grids if the first row is the same)",
      images: [
        { 
          src: require('../assets/images/Sudoku/gridInspection.png'), 
          alt: "Grid inspection process",
          size: 'full'
        }
      ],
      imageLayout: 'singleColumnFixed'
    },
    {
      title: "Displaying Solution",
      content: "The identified grid is passed into the solver and the solution is drawn onto the warped image where the missing digits in the grid were. The warped area is then unwarped and added onto the original webcam image with a black polygon covering the old grid.",
      images: [
        { 
          src: require('../assets/images/Sudoku/displayingSolution1.png'), 
          alt: "Solved Sudoku grid"
        },
        { 
          src: require('../assets/images/Sudoku/FilledSol2.png'), 
          alt: "Neural network solution"
        }
      ],
      imageLayout: 'twoColumn'
    }
  ],
  
  // Project ID 7 - Stirling Engine
  "7": [
    
    {
      title: "",
      content: "",
      images: [
        { 
          src: require('../assets/images/StirlingEngine/Engine3.jpg'), 
          alt: "Stirling Engine Components"
        },
        { 
          src: require('../assets/images/StirlingEngine/Engine4.jpg'), 
          alt: "Stirling Engine Assembly"
        }
      ],
      imageLayout: 'twoColumn'
    },
  ],
  
  // Project ID 5 - Pendulum Swing-Up
  "5": [
    {
      title: "Kinodynamic Planning",
      content: "The implementation uses a sampling-based kinodynamic path planning approach, which accounts for both the kinematic and dynamic constraints of the system. This allows for finding feasible trajectories that respect the physical limitations of the pendulum.<br><br>This implementation finds a path in roughly 10,000 iterations. Kinodynamic planning can be generalized to more complex higher degree of freedom dynamic systems at the cost of higher run time.",
      images: [
        {
          src: require('../assets/images/PendulumSwingUp/StateSpacePendulum.avif'),
          alt: "State space of pendulum",
          aspectRatio: "75%", // 4:3
        }
      ],
      imageLayout: 'singleColumnFixed'
    }
  ],
  
  // Project ID 6 - Path Planning
  "6": [
    {
      title: "Pseudocode",
      content: "Initialize tree with start point<br>For n = 1 to N<br>&nbsp;&nbsp;q = random point<br>&nbsp;&nbsp;(q = q_goal every few iterations)<br>&nbsp;&nbsp;If q is not in collision<br>&nbsp;&nbsp;&nbsp;&nbsp;Find nearest node in Tree<br>&nbsp;&nbsp;&nbsp;&nbsp;Extend q in direction of nearest node<br>&nbsp;&nbsp;&nbsp;&nbsp;Add q_extended to Tree<br>&nbsp;&nbsp;&nbsp;&nbsp;If q_extended is equal to q_goal<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;break<br><br>High-level pseudocode for general RRT algorithm is shown. Generally, the tree exists in the configuration space of the system and the obstacles exist in the workspace. For this demo, the obstacles and tree were put in the same space for better visualization of the algorithm.",
      images: [
        // { 
        //   src: require('../assets/gifs/pathPlanning.gif'), 
        //   alt: "RRT Pseudocode and Execution",
        //   size: 'full'
        // }
      ],
      imageLayout: 'row'
    }
  ],
  
  // Project ID 10 - Aerial Robotics
  "10": [
    {
      title: "Folding Plane Design",
      content: "Key design requirements included a minimum four-foot wingspan, fitting within a 3x2 foot box when stored, remote wing unfolding and locking, a rotating radome, carrying at least four attack stores, taking off from a ten-foot ramp, and incorporating a tail hook for launch. My primary contribution during my year in the club was assisting with the wing locking mechanism.",
      images: [
        { 
          src: require('../assets/images/AerialRobotics/PennAir2.png'), 
          alt: "Flight controller system architecture",
          size: 'medium'
        },
        { 
          src: require('../assets/images/AerialRobotics/PennAir3.png'), 
          alt: "Wing folding mechanism",
          size: 'medium'
        }
      ],
      imageLayout: 'row'
    },
    {
      title: "Flight Demo",
      content: "",
      video: {
        src: require('../assets/images/AerialRobotics/PennAirVideo.mp4'),
        poster: require('../assets/images/AerialRobotics/pennAirThumbnail.png')
      }
    },
  ],
  
  // Real Robot Arm project
  "11": [
    {
      title: "Camera-to-Robot Calibration",
      content: "The first step was creating a mapping from camera space to robot coordinates. Using a checkerboard pattern, I calibrated the camera to remove distortion and calculated the transformation matrix between camera view and robot workspace. This allowed accurate conversion of any point in the image to physical robot coordinates.",
      images: [
        {
          src: require('../assets/images/RealRobotArm/block_with_plane.png'), 
          alt: "Camera calibration grid overlay on workspace",
          size: 'medium'
        },
        { 
          src: require('../assets/images/RealRobotArm/checkerboard.png'), 
          alt: "Checkerboard pattern with detected grid points",
          size: 'medium'
        }
      ],
      imageLayout: 'twoColumn'
    },
    {
      title: "Block Detection System",
      content: "I implemented vision-based block detection using HSV color space filtering. This approach provided robust identification under varying lighting conditions. Detected blocks were tracked in real-time, with their positions transformed from camera coordinates to the robot's coordinate system.",
      imageLayout: 'singleColumnFixed',
      images: [
        { 
          src: require('../assets/images/RealRobotArm/yellow_block_detected.png'), 
          alt: "Vision system detecting yellow block",
          size: 'medium'
        }
      ]
    },
    {
      title: "Path Planning and Inverse Kinematics",
      content: "Once block positions were determined, inverse kinematics calculated the joint configurations needed to reach each target. The algorithm generated optimal paths for the robot arm, considering joint limits and avoiding potential collisions.",
      imageLayout: 'singleColumnFixed'
    },
    {
      title: "Control System Implementation",
      content: "An Arduino-based server received the calculated robot configurations and executed the physical movements. This server handled timing and sequencing, ensuring the robot precisely followed planned paths while continuously updating based on new visual information.",
      imageLayout: 'singleColumnFixed'
    }
  ],
  
  // Add more projects here
};

const ProjectArticle: React.FC<ProjectArticleProps> = ({ projectId }) => {
  // Get the article sections for this project
  const sections = projectArticles[projectId];
  
  // If no content is available for this project, don't render anything
  if (!sections || sections.length === 0) {
    return null;
  }

  // Function to render images based on layout
  const renderImages = (section: ArticleSection) => {
    if (!section.images || section.images.length === 0) {
      return null;
    }

    const isFullWidthLayout = section.imageLayout === 'row' && section.images.length === 1;

    switch (section.imageLayout) {
      case 'row':
        return (
          <ImageRow>
            {section.images.map((image, imgIndex) => (
              <ImageWrapper key={imgIndex} size={image.size}>
                <ImageContainer isFullWidth={image.size === 'full'} aspectRatio={image.aspectRatio}>
                  <StyledArticleImage 
                    src={image.src} 
                    alt={image.alt} 
                  />
                </ImageContainer>
              </ImageWrapper>
            ))}
          </ImageRow>
        );
      case 'twoColumn':
        return (
          <TwoColumnGrid>
            {section.images.map((image, imgIndex) => (
              <ImageContainer key={imgIndex} aspectRatio={image.aspectRatio}>
                <StyledArticleImage 
                  src={image.src} 
                  alt={image.alt} 
                />
              </ImageContainer>
            ))}
          </TwoColumnGrid>
        );
      case 'threeColumn':
        return (
          <ThreeColumnGrid>
            {section.images.map((image, imgIndex) => (
              <ImageContainer key={imgIndex} aspectRatio={image.aspectRatio}>
                <StyledArticleImage 
                  src={image.src} 
                  alt={image.alt} 
                />
              </ImageContainer>
            ))}
          </ThreeColumnGrid>
        );
      case 'singleColumnFixed':
        return (
          <SingleColumnFixed size={section.images[0]?.size}>
            {section.images.map((image, imgIndex) => (
              <ImageContainer key={imgIndex} aspectRatio={image.aspectRatio}>
                <StyledArticleImage 
                  src={image.src} 
                  alt={image.alt} 
                />
              </ImageContainer>
            ))}
          </SingleColumnFixed>
        );
      case 'grid':
      default:
        return (
          <ImageGrid>
            {section.images.map((image, imgIndex) => (
              <ImageContainer key={imgIndex} aspectRatio={image.aspectRatio}>
                <StyledArticleImage 
                  src={image.src} 
                  alt={image.alt} 
                />
              </ImageContainer>
            ))}
          </ImageGrid>
        );
    }
  };

  // Function to render video if present
  const renderVideo = (section: ArticleSection) => {
    if (!section.video) {
      return null;
    }

    return (
      <VideoContainer>
        <video 
          controls 
          poster={section.video.poster}
          preload="metadata"
        >
          <source src={section.video.src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </VideoContainer>
    );
  };

  return (
    <ArticleContainer>
      {sections.map((section, index) => (
        <SectionContainer key={index}>
          <SectionTitle>{section.title}</SectionTitle>
          <SectionContent dangerouslySetInnerHTML={{ __html: section.content }} />
          
          {renderVideo(section)}
          {renderImages(section)}
        </SectionContainer>
      ))}
    </ArticleContainer>
  );
};

export default ProjectArticle; 