import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 5%;
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2rem 1rem;
  }
`;

const Logo = styled(Link)`
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--primary-color);
  letter-spacing: 1px;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const Menu = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 768px) {
    gap: 1.5rem;
  }
`;

const MenuItem = styled(Link)<{ isActive?: boolean }>`
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: color 0.3s ease;
  color: ${props => props.isActive ? 'var(--accent-color)' : 'var(--primary-color)'};
  font-weight: ${props => props.isActive ? '600' : 'normal'};

  &:hover {
    color: var(--accent-color);
  }
`;

const Navbar: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  return (
    <Nav style={{ 
      boxShadow: scrolled ? '0 2px 10px rgba(0, 0, 0, 0.1)' : 'none',
      transition: 'box-shadow 0.3s ease' 
    }}>
      <Logo to="/">ALAN NUNEZ</Logo>
      <Menu>
        <MenuItem to="/" isActive={location.pathname === '/'}>PORTFOLIO</MenuItem>
        <MenuItem to="/resume" isActive={location.pathname === '/resume'}>RESUME</MenuItem>
        <MenuItem to="/about" isActive={location.pathname === '/about'}>ABOUT</MenuItem>
      </Menu>
    </Nav>
  );
};

export default Navbar; 