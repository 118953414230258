import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const PageContainer = styled.div`
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 2rem 0;
`;

const ContentSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  padding: 0 5%;
  margin-top: 3rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 1rem;
  }
`;

const ProfileColumn = styled(motion.div)`
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

const ProfileImage = styled.div`
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 2rem;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  width: 100%;
`;

const ContactItem = styled.div`
  font-size: 1.1rem;
`;

const BioColumn = styled(motion.div)`
  flex: 2;
  min-width: 300px;
  
  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

const BioSection = styled.div`
  margin-bottom: 2rem;
`;

const BioSectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--accent-color);
`;

const BioParagraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
`;

const About: React.FC = () => {
  return (
    <PageContainer>
      <ContentSection>
        <ProfileColumn
          initial={{ opacity: 0, x: -30 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <ProfileImage>
            <img src={require('../assets/images/portrait.avif')} alt="Alan Nunez" />
          </ProfileImage>
          {/* <ContactInfo>
            <ContactItem>
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
                style={{ width: '1em', height: '0.75em', marginRight: '8px' }}
              >
                <rect x="2" y="4" width="20" height="16" rx="2"></rect>
                <path d="M22 7l-10 7L2 7"></path>
              </svg>
              alannunez1679 at gmail dot com
            </ContactItem>
          </ContactInfo> */}
        </ProfileColumn>
        
        <BioColumn
          initial={{ opacity: 0, x: 30 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <BioSection>
            <BioSectionTitle>About me</BioSectionTitle>
            <BioParagraph>
              I'm a Robotics Engineer with a Master's degree from the University of Pennsylvania, combining mechanical engineering expertise with software development skills.
            </BioParagraph>
            <BioParagraph>
              This site demonstrates some of the projects I had fun working on and the design and fabrication experience I gained from each of them. On my free time, you can find me animating 2D graphics or baking 100% whole wheat sourdough bread.
            </BioParagraph>
          </BioSection>
          
         
        </BioColumn>
      </ContentSection>
    </PageContainer>
  );
};

export default About; 