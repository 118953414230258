import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import placeholderImage from '../assets/images/placeholder.jpg';

// Import poster images
import pathPlanningPoster from '../assets/images/posters/PathPlanning_pathPlanning_poster.jpg';
import realStackingPoster from '../assets/images/posters/RealRobotArm_realStacking_poster.jpg';
import pennAirVideoPoster from '../assets/images/posters/AerialRobotics_PennAirVideo_poster.jpg';
import sudokuPoster from '../assets/images/posters/Sudoku_soduko_poster.jpg';
import pendulumStateSpacePoster from '../assets/images/posters/PendulumSwingUp_stateSpace_poster.jpg';
import pendulumSwingUpPoster from '../assets/images/posters/PendulumSwingUp_SwingUp_poster.jpg';
import robotManipulatorPoster from '../assets/images/posters/RobotManipulator_RobotManipulator_poster.jpg';
import tournamentPoster from '../assets/images/posters/RobotManipulator_tournament_poster.jpg';

interface ProjectCardProps {
  id: number;
  title: string;
  imageUrl: string;
  description: string;
  link?: string;
  startTime?: number;
  endTime?: number;
  showHoverIndicator?: boolean;
}

const Card = styled(motion.div)`
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  cursor: pointer;
  height: 0;
  padding-bottom: 75%; /* Creates a 4:3 aspect ratio (landscape) */
  background-color: #ffffff;
  
  &:hover .overlay {
    opacity: 1;
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  ${Card}:hover img,
  ${Card}:hover video {
    transform: scale(1.05);
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  opacity: 0;
  transition: opacity 0.3s ease;
  color: #333;
  text-align: center;
  
  h3 {
    font-size: 1.5rem;
    font-weight: 300;
  }
`;

const HoverIndicator = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(0, 0, 0, 0.25);
  color: white;
  padding: 8px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  z-index: 10;
  opacity: 0.7;
  
  svg {
    width: 24px;
    height: 24px;
  }
  
  ${Card}:hover & {
    opacity: 0;
  }
`;

const ProjectCard: React.FC<ProjectCardProps> = ({ 
  id, 
  title, 
  imageUrl, 
  description, 
  link, 
  startTime = 1, 
  endTime = 5,
  showHoverIndicator = false,
}) => {
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  
  const isVideo = typeof imageUrl === 'string' && 
    (imageUrl.endsWith('.mp4') || imageUrl.endsWith('.webm') || imageUrl.endsWith('.ogg'));
  
  // Function to get the appropriate poster image based on the video URL
  const getPosterImage = (videoUrl: string) => {
    if (videoUrl.includes('pathPlanning')) return pathPlanningPoster;
    if (videoUrl.includes('realStacking') || videoUrl.includes('stackingShort')) return realStackingPoster;
    if (videoUrl.includes('PennAirVideo')) return pennAirVideoPoster;
    if (videoUrl.includes('soduko')) return sudokuPoster;
    if (videoUrl.includes('stateSpace')) return pendulumStateSpacePoster;
    if (videoUrl.includes('SwingUp')) return pendulumSwingUpPoster;
    if (videoUrl.includes('RobotManipulator')) return robotManipulatorPoster;
    if (videoUrl.includes('tournament')) return tournamentPoster;
    return placeholderImage; // Fallback to placeholder if no match
  };
  
  // Get the appropriate poster image for this video
  const posterImage = isVideo ? getPosterImage(imageUrl) : placeholderImage;
  
  // Check if the device is mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    // Initial check
    checkMobile();
    
    // Check on resize
    window.addEventListener('resize', checkMobile);
    
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);
  
  // Set up video element to play
  useEffect(() => {
    if (isVideo && videoRef.current) {
      const video = videoRef.current;
      
      // Set the initial time when the video is loaded
      const handleLoadedData = () => {
        video.currentTime = startTime;
        
        // iOS has strict autoplay policies
        if (isMobile) {
          // Don't try to autoplay on mobile devices
          video.pause();
        } else {
          // Don't autoplay until hovered on desktop
          video.pause();
        }
      };
      
      // Loop
      const handleTimeUpdate = () => {
        if (video.currentTime >= endTime) {
          video.currentTime = startTime;
        }
      };
      
      video.addEventListener('loadeddata', handleLoadedData);
      video.addEventListener('timeupdate', handleTimeUpdate);
      
      return () => {
        video.removeEventListener('loadeddata', handleLoadedData);
        video.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [isVideo, startTime, endTime, isMobile]);
  
  // Control video playback based on hover state (only for non-mobile)
  useEffect(() => {
    if (isVideo && videoRef.current && !isMobile) {
      if (isHovered) {
        videoRef.current.play().catch(error => {
          console.log('Video play failed:', error);
        });
      } else {
        videoRef.current.pause();
      }
    }
  }, [isHovered, isVideo, isMobile]);

  const handleClick = () => {
    if (link) {
      window.open(link, '_blank');
    } else {
      navigate(`/projects/${id}`);
    }
  };
  
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Card 
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      whileHover={{ y: -5 }}
      transition={{ duration: 0.3 }}
    >
      {showHoverIndicator && !isMobile && (
        <HoverIndicator>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" strokeWidth="0" strokeLinecap="round" strokeLinejoin="round">
            <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="2"></circle>
            <polygon points="10,8 16,12 10,16" fill="currentColor"></polygon>
          </svg>
        </HoverIndicator>
      )}
      <ImageContainer>
        {isVideo ? (
          isMobile ? (
            <img src={posterImage} alt={title} />
          ) : (
            <video
              ref={videoRef}
              muted
              loop
              playsInline
              poster={posterImage}
            >
              <source src={imageUrl} type="video/mp4" />
              <img src={posterImage} alt={title} />
            </video>
          )
        ) : (
          <img src={imageUrl} alt={title} />
        )}
      </ImageContainer>
      {/* <Overlay className="overlay">
        <h3>{title}</h3>
      </Overlay> */}
    </Card>
  );
};

export default ProjectCard; 